import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  forgotUsernameLink,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
  setErrors,
} from "actions/auth";
import { sfold, checkScripts } from "utils/sFold";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import Spinner from "views/Spinner";
import { Img } from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";
import { Helmet } from "react-helmet";
import extractNumber from "utils/extractNumber";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { validateForm } from "utils/validation";
import { uniqueString } from "utils/nodeHelper";

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const ResetLink = ({
  forgotUsernameLink,
  loadPage,
  loading,
  loadingOnLoginSubmit,
  loginRedirect,
  setErrors,
  errorList,
  history,
}) => {
  //################## For css ##################
  const [loadingCSS, setLoadingCSS] = useState(true);
  const [onlyOnce, setOnce] = useState(true);
  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;

  const InjectCSS = async () => {
    try {
      const cssArray = [
        "/assets/lab/plugins/bootstrap/css/bootstrap.min.css",
        "/assets/lab/css/style.css",
        "/assets/lab/css/custom.css",
        "/assets/lab/color-skins/color10.css",
      ];
      stylesNeedToBeLoaded = cssArray.length;
      if (!checkScripts("lab_panel_css"))
        cssArray.forEach((url) => {
          addStyleScript(url, "css", "lab_panel_css");
        });
      else setLoadingCSS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    let tag,
      tag_id = sfold(package_name);
    switch (type) {
      case "css":
        tag = document.createElement("link");
        tag.rel = "stylesheet";
        tag.href = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => styleLoaded(tag_id);
        break;
      default:
        return;
    }
    document.head.appendChild(tag);
  };

  useMemo(() => {
    if (onlyOnce) {
      InjectCSS();
      loadPage();
      setOnce(false);
    }
  }, [loadPage]);

  var today = new Date();
  //today.setFullYear(today.getFullYear() - 16);
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  const initialState = {
    name: "",
    email: "",
    ccode: "+1",
    phone: "",
    iso: "US",
    iute164_phone: "",
    dob: "",
    minDate: today,
  };
  const [formData, setFormData] = useState(initialState);
  //############### Define form state ###############################

  const { dob, name, email, ccode, iso, iute164_phone, minDate } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onPhoneChange = (number) => {
    const phone = extractNumber(number, ccode);
    console.log(phone, ccode);
    setFormData((form) => ({
      ...form,
      phone: phone,
      iute164_phone: number || ccode,
    }));
  };
  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };
  const onDateChange = (e, time) => {
    setFormData({
      ...formData,
      dob: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let error = validateForm(formData, [
      {
        param: "name",
        msg: "Please provide the name",
      },
      {
        param: "dob",
        msg: "Please provide a valid date of birth",
      },
    ]);

    let submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    loadingOnLoginSubmit();
    forgotUsernameLink(submitData).then((res) => {
      loadPage();
    });
  };

  return loadingCSS || loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Forgot username - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <div className="page page-h">
        <div className="page-content z-index-10">
          <Container>
            <Row>
              <Col xl="4" md="12" className="d-block mx-auto">
                <div className="text-center w-100 d-block mb-4">
                  <Img alt="" src={LogoSvg} className="w-70" />
                </div>

                <Card className="mb-0">
                  <CardHeader>
                    <h3 className="card-title">Forgot Username</h3>
                  </CardHeader>
                  <CardBody>
                    <Alert />
                    <Form onSubmit={(e) => onSubmit(e)}>
                      <FormGroup>
                        <Label className="form-label" htmlFor="name">
                          Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          maxLength="25"
                          value={name}
                          required
                          autoComplete={uniqueString()}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.name ? true : false}
                        />
                        <Errors current_key="name" key="name" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label" htmlFor="dob">
                          {" "}
                          Date of Birth
                        </Label>
                        <Input
                          type="Date"
                          id="example-datepicker"
                          value={dob}
                          max={minDate}
                          onChange={(e) => onDateChange(e)}
                        />
                        <Errors current_key="dob" key="dob" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label" htmlFor="email">
                          Email
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          maxLength="50"
                          value={email}
                          autoComplete={uniqueString()}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label" htmlFor="phone">
                          Phone
                        </Label>
                        <PhoneInput
                          className="hk_custom_sag"
                          autoComplete={uniqueString()}
                          international={true}
                          defaultCountry="US"
                          countryOptionsOrder={["US", "|", "..."]}
                          value={iute164_phone}
                          name="phone"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <Errors current_key="phone" key="phone" />
                      </FormGroup>
                      <div className="form-footer mt-2">
                        <button className="btn btn-primary btn-block">
                          Submit
                        </button>
                      </div>
                      <div className="text-center  mt-3 text-dark">
                        <Link
                          to="#"
                          onClick={(e) => loginRedirect(history, "member")}
                        >
                          <b>Sign In</b>
                        </Link>
                        &nbsp;here
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

ResetLink.propTypes = {
  forgotUsernameLink: PropTypes.func.isRequired,
  loadingOnLoginSubmit: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  forgotUsernameLink,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
  setErrors,
})(ResetLink);
