import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  FRONTMEMBER_ERROR,
  FRONTMEMBER_GET_BY_ID,
  FRONTMEMBER_LOADING_ON_SUBMIT,
  REMOVE_ERRORS,
  FRONTMEMBER_RESET,
  FRONTMEMBER_LOAD_PAGE,
  FRONTMEMBER_UPDATED,
  FRONTMEMBER_INSURANCE_ON_SUBMIT,
  FRONTMEMBER_INSURANCE_UPDATED,
  FRONTMEMBER_CONSENT_FORM_ON_SUBMIT,
  FRONTMEMBER_CONSENT_FORM_SAVED,
  FRONTMEMBER_IMMEGRATION__FORM_KEYS_UPDATED,
  FRONTMEMBER_IMMIGRATION_UPDATED,
  FRONTMEMBER_IMMIGRATION_DATA_UPDATED,
} from "actions/types";
import { logout } from "actions/auth";

// Get Member by id
export const getMemberById = (member_id, type) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnMemberSubmit());
    var res;
    res = await axios.get(`/api/patient/${member_id}`, config);
    if (res.data.status) {
      await dispatch({
        type: FRONTMEMBER_GET_BY_ID,
        payload: res.data.response,
      });
    }

    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTMEMBER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

const get_params = (formData) => {
  let params = new window.FormData();
  const formFields = [
    "line_1",
    "line_2",
    "zip_code",
    "user_id",
    "iso",
    "secondaryUsername",
    "fullName",
    "phone",
    "email",
    "dob",
    "ccode",
    "employerId",
    "isChild",
    "paymentBy",
    "gender",
    "insuranceCompany",
    "subscriberId",
    "parentsApproval",
    "collectInsuranceData",
    "insuranceDetails",
    "isPregnant",
    "referralAuthority",
    "icdValidCode",
    "ethnicity",
    "ssn",
    "jobNumber",
    "race",
    "memberPassword",
    "vaccineType",
    "firstDoseDone",
    "firstDoseDate",
    "secondDoseDone",
    "secondDoseDate",
    "boosterDone",
    "boosterDate",
    "vaccinationCardFront",
    "vaccinationCardBack",
  ];
  const formObjectFields = ["a_city", "companyLocation"];
  formFields.forEach((field) => {
    if (formData[field]) {
      params.append(field, formData[field]);
    }
  });
  formObjectFields.forEach((field) => {
    if (formData[field]) {
      if (field === "companyLocation")
        params.append(field, formData[field].value);
      else params.append(field, JSON.stringify(formData[field]));
    }
  });

  return params;
};

// Edit Member
export const edit = (formData, history, member_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnMemberSubmit());
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.put(`/api/patient/${member_id}`, params, config);

    if (res.data.status === true) {
      dispatch({
        type: FRONTMEMBER_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Patient Updated.", "success"));
      history.push("/patient/profile");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTMEMBER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTMEMBER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Get parient insurance card by id
export const getInsuranceCard = (member_id) => async (dispatch) => {
  try {
    const config = { responseType: "blob" };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/patient/${member_id}/get-insurance-card?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "The insurance card file does not exist or something went wrong.",
        "danger"
      )
    );
  }
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/patient/page-not-found");
};

// Dispatch Loading
export const loadingOnMemberSubmit = () => async (dispatch) => {
  await dispatch({ type: FRONTMEMBER_LOADING_ON_SUBMIT });
};

// Cancel save
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/patients");
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: FRONTMEMBER_RESET });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: FRONTMEMBER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: FRONTMEMBER_LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

const getInsuranceParams = (formData) => {
  let params = new window.FormData();
  Object.keys(formData).forEach((field) => {
    params.append(field, formData[field]);
  });

  return params;
};

// Save Insurance data
export const saveInsuranceData = (formData, patient_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTMEMBER_INSURANCE_ON_SUBMIT,
  });
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const params = getInsuranceParams(formData);
    const res = await axios.put(
      `/api/patient/${patient_id}/save-insurance-data`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTMEMBER_INSURANCE_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Insurance data updated.", "success"));
      // history.push("/organization/patients");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTMEMBER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          // if(error.param==="description")
          // dispatch(setAlert(error.msg, "danger"));
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTMEMBER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Get parient insurance card front by id
export const getInsuranceCardFront =
  (patient_id, insuranceType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        insuranceType === "primary"
          ? "get-primary-insurance-card/front"
          : "get-secondary-insurance-card/front";
      const res = await axios.get(
        `/api/patient/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The insurance card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get parient insurance card back by id
export const getInsuranceCardBack =
  (patient_id, insuranceType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        insuranceType === "primary"
          ? "get-primary-insurance-card/back"
          : "get-secondary-insurance-card/back";
      const res = await axios.get(
        `/api/patient/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The insurance card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get Insurance Name List
export const getInsuranceNameList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get(`/api/patient/insuranceNameList`, config);
    return res.data.response;
  } catch (err) {
    console.log(err);
  }
};

// Get vaccination card
export const getVaccinationCard =
  (patient_id, cardType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        cardType === "front"
          ? "get-vaccination-card/front"
          : "get-vaccination-card/back";
      const res = await axios.get(
        `/api/patient/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The vaccination card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

export const getConsentPatientAction = (patient_id) => async (dispatch) => {
  try {
    const config = { responseType: "blob" };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/patient/${patient_id}/get-consent-patient/?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "Either the file does not exist or something went wrong.",
        "danger"
      )
    );
  }
};

export const getConsentFormContentWeb = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    var res = await axios.get(`/api/get-consent-form/web`, config);

    if (res.data.status === true) {
    } else {
      dispatch({
        type: FRONTMEMBER_ERROR,
        payload: {
          msg: "Something Went Wrong",
          status: res.data.status,
          data: res.data,
        },
      });
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTMEMBER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

// Save consent form
export const saveConsentForm = (formData, patient_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.put(
      `/api/patient/${patient_id}/member-save-consent-form`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTMEMBER_CONSENT_FORM_SAVED,
        payload: res.data.response,
      });
      dispatch(setAlert("Consent form saved.", "success"));
      return res.data.response;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTMEMBER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTMEMBER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

export const savePatientImmigration =
  (patient_id, immigration_id, formData, history) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      dispatch({ type: REMOVE_ERRORS });

      let res = null;
      if (immigration_id) {
        res = await axios.put(
          `/api/patient/${patient_id}/immigration/${immigration_id}`,
          formData,
          config
        );
      } else {
        res = await axios.post(
          `/api/patient/${patient_id}/immigration`,
          formData,
          config
        );
      }

      if (res.data.status === true) {
        dispatch(setAlert("Patient immigration data saved.", "success"));
        history.push("/patient/profile");
        dispatch({
          type: FRONTMEMBER_IMMIGRATION_UPDATED,
        });
      }

      return res.data;
    } catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTMEMBER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
        }
      }
    }
  };

export const getPatientImmigrationData = (patient_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    dispatch({ type: REMOVE_ERRORS });

    const res = await axios.get(
      `/api/patient/${patient_id}/immigration`,

      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: FRONTMEMBER_IMMIGRATION_DATA_UPDATED,
        payload: res.data.response,
      });
    }

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTMEMBER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

export const getImmigrationFormKeys =
  (patient_id, language) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.get(
        `/api/patient/${patient_id}/immigration/keys?language=${language}`,
        config
      );

      dispatch({
        type: FRONTMEMBER_IMMEGRATION__FORM_KEYS_UPDATED,
        payload: res.data.response,
      });

      return res.data;
    } catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            setAlert(
              "Either the file does not exist or something went wrong.",
              "danger"
            )
          );
        }
      }
    }
  };

export const downloadI693PDF = (patient_id, language) => async (dispatch) => {
  try {
    const config = {
      responseType: "blob",
    };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/patient/${patient_id}/immigration-pdf?language=${language}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTMEMBER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

export const getPatientSignature = (patient_id) => async (dispatch) => {
  try {
    const config = {
      responseType: "blob",
    };
    const res = await axios.get(
      `/api/patient/${patient_id}/get-signature`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTMEMBER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

export const savePatientSignature =
  (patient_id, formData) => async (dispatch) => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/patient/${patient_id}/save-signature`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch(setAlert("Signature saved.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTMEMBER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch({
          type: FRONTMEMBER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  };
