import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import {
  loginMember,
  forgotPassword,
  forgotUsername,
  loadAuthPage,
  registerRedirect,
  loadUser,
  loadPage,
} from "actions/auth";
import Alert from "views/Notifications/Alert";
import Spinner from "views/Spinner";
import { Img } from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";
import { sfold, checkScripts } from "utils/sFold";
import * as Constants from "constants/index";
import Errors from "views/Notifications/Errors";
import { Helmet } from "react-helmet";
import extractNumber from "utils/extractNumber";
import { uniqueString } from "utils/nodeHelper";

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Button,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { ENABLE_RESEND_OTP_OPTION } from "../../../actions/types";
import { removeErrors } from "actions/front/Member/MemberProfile";

const MemberLogin = ({
  loginMember,
  loadUser,
  loadPage,
  loading,
  isAuthenticated,
  otpSentForLogin,
  enableResendOTPOption,
  authId,
  forgotPassword,
  forgotUsername,
  loadAuthPage,
  history,
  errorList,
  removeErrors,
}) => {
  const dispatch = useDispatch();

  //############### For adding Css ###########################
  const [loadingCSS, setLoadingCSS] = useState(true);
  const [type, setPassType] = useState("password");
  const [onlyOnce, setOnce] = useState(true);
  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;
  const InjectCSS = async () => {
    try {
      const cssArray = [
        "/assets/lab/plugins/sidemenu/sidemenu.css",
        "/assets/lab/plugins/bootstrap/css/bootstrap.min.css",
        "/assets/lab/css/style.css",
        "/assets/lab/css/admin-custom.css",
        "/assets/lab/css/custom.css",
        "/assets/lab/css/icons.css",
        "/assets/lab/color-skins/color10.css",
      ];
      stylesNeedToBeLoaded = cssArray.length;
      if (!checkScripts("lab_panel_css"))
        cssArray.forEach((url) => {
          addStyleScript(url, "css", "lab_panel_css");
        });
      else setLoadingCSS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    let tag,
      tag_id = sfold(package_name);
    switch (type) {
      case "css":
        tag = document.createElement("link");
        tag.rel = "stylesheet";
        tag.href = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => styleLoaded(tag_id);
        break;
      default:
        return;
    }
    document.head.appendChild(tag);
  };

  const requestResendOTP = () => {
    setShowResendButton(false);

    const submitData = {
      password: formData.password,
      askForTwoFactor: true,
      requestResendOTP: true,
      userType: Constants.EMPLOYEE_ROLE,
      loginWith: formData.loginWith,
      usernameOrEmail:
        formData.loginWith == "username" ? formData.usernameOrEmail : undefined,
      iute164_phone:
        formData.loginWith == "phone"
          ? formData.ccode + formData.phone
          : undefined,
    };

    console.log("Request resend OTP. submitData", submitData);
    loginMember(submitData, history).then((res) => {
      console.log("two factor asked.", "\n", "Resend OTP option enabled.");
      dispatch({
        type: ENABLE_RESEND_OTP_OPTION,
      });
      showResendOTPOptionWithTimer();
    });
  };

  useMemo(() => {
    if (onlyOnce) {
      InjectCSS();
      loadAuthPage(history);
      setOnce(false);
    }
    loadPage();
  }, [loadPage]);

  //#####################Set Form Data
  const [formData, setFormData] = useState({
    password: "",
    remember_me: "",
    otp: "",
    userType: Constants.EMPLOYEE_ROLE,
    usernameOrEmail: "",
    phone: "",
    iso: "US",
    ccode: "+1",
    iute164_phone: "",
    loginWith: "username",
  });

  const {
    password,
    remember_me,
    otp,
    usernameOrEmail,
    phone,
    iso,
    ccode,
    iute164_phone,
    loginWith,
  } = formData;

  const onChange = (e) => {
    if (e.target.name === "remember_me") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [loginOrTwoFactor, setloginOrTwoFactor] = useState("login");

  const onPhoneChange = (number) => {
    const phone = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      phone: phone,
      iute164_phone: number || ccode,
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  const loginWithCSS = {
    //marginLeft: "5px",
    textTransform: "underline",
    //color: "#20a8d8",
    cursor: "pointer",
    float: "right",
  };

  //######################## Submit form ##################################
  const onSubmit = (e) => {
    e.preventDefault();
    let submitData = {
      password: formData.password,
      askForTwoFactor: false,
      userType: Constants.EMPLOYEE_ROLE,
      loginWith: formData.loginWith,
    };
    if (formData.loginWith == "username") {
      submitData = {
        ...submitData,
        usernameOrEmail: formData.usernameOrEmail,
      };
    } else {
      submitData = {
        ...submitData,
        iute164_phone: formData.iute164_phone,
      };
    }
    loadAuthPage(history);
    loginMember(submitData, history).then((res) => {
      dispatch({
        type: ENABLE_RESEND_OTP_OPTION,
      });
      showResendOTPOptionWithTimer();
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/patient/profile");
    }

    if (otpSentForLogin === false) {
      setloginOrTwoFactor("login");
    }
    if (otpSentForLogin === true) {
      setloginOrTwoFactor("twofactor");
    }
  }, [isAuthenticated, loadUser, otpSentForLogin]);

  const onSubmitTwoFactor = (e) => {
    e.preventDefault();
    loadAuthPage(history);
    let submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }
    loginMember(submitData, history);
  };
  const showHide = () => {
    setPassType(type === "input" ? "password" : "input");
  };
  const [showResendButton, setShowResendButton] = useState(false);
  const [textToShowForResendTimer, setTextToShowForResendTimer] = useState("");
  const showResendOTPOptionWithTimer = () => {
    var timeLeft = 60;
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        setShowResendButton(true);
        setTextToShowForResendTimer("");
        clearInterval(downloadTimer);
      } else {
        setTextToShowForResendTimer("Resend OTP in " + timeLeft + " seconds");
      }
      timeLeft -= 1;
    }, 1000);
  };

  function alterLoginWith() {
    removeErrors();
    if (formData.loginWith == "username") {
      setFormData({
        ...formData,
        loginWith: "phone",
        usernameOrEmail: "",
      });
    } else {
      setFormData({
        ...formData,
        loginWith: "username",
        iso: "US",
        phone: "+1",
        iute164_phone: "",
      });
    }
  }

  return loadingCSS || loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Login - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <div className="page page-h">
        <div className="page-content z-index-10">
          <Container>
            <Row>
              <Col xl="4" md="12" className="d-block mx-auto">
                <div className="text-center w-100 d-block mb-4">
                  <Img alt="" src={LogoSvg} className="w-70" />
                </div>

                <Card className="mb-0">
                  {loginOrTwoFactor === "login" && (
                    <Form onSubmit={(e) => onSubmit(e)}>
                      <CardHeader>
                        <h4 className="card-title">Patient Login</h4>
                      </CardHeader>
                      <Alert />
                      <CardBody>
                        {loginWith == "username" ? (
                          <FormGroup>
                            <Label className="form-label text-dark">
                              Username or email
                              <Link
                                //to="#"
                                className="float-right small"
                                onClick={alterLoginWith}
                              >
                                Login with phone?
                              </Link>
                            </Label>
                            <Input
                              type="usernameOrEmail"
                              autoComplete={uniqueString()}
                              name="usernameOrEmail"
                              maxLength="100"
                              value={usernameOrEmail}
                              onChange={(e) => onChange(e)}
                              required
                              invalid={errorList.usernameOrEmail ? true : false}
                            />
                            <Errors
                              current_key="usernameOrEmail"
                              key="usernameOrEmail"
                            />
                            <Link
                              to="#"
                              className="float-right small text-dark mt-1"
                              onClick={(e) => forgotUsername(history, "member")}
                            >
                              Forgot username?
                            </Link>
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <Label className="form-label" htmlFor="phone">
                              Phone
                              <Link
                                //to="#"
                                className="float-right small"
                                onClick={alterLoginWith}
                              >
                                Login with username or email?
                              </Link>
                            </Label>
                            <PhoneInput
                              className="hk_custom_sag"
                              autoComplete={uniqueString()}
                              international={true}
                              defaultCountry="US"
                              countryOptionsOrder={["US", "|", "..."]}
                              value={iute164_phone}
                              name="phone"
                              addInternationalOption={false}
                              onChange={onPhoneChange}
                              onCountryChange={onCountryChange}
                            />
                            <Errors
                              current_key="iute164_phone"
                              key="iute164_phone"
                            />
                          </FormGroup>
                        )}
                        <FormGroup className="password-box">
                          <Label className="form-label text-dark">
                            Password
                          </Label>
                          <Input
                            type={type}
                            autoComplete={uniqueString()}
                            name="password"
                            minLength="8"
                            value={password}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <i
                            className={
                              type === "input"
                                ? "mr-1 mt-1 eye-symbol fa fa-eye eye-login"
                                : "mr-1 mt-1 eye-symbol fa fa-eye-slash eye-login"
                            }
                            onClick={showHide}
                          ></i>
                        </FormGroup>

                        <FormGroup>
                          <Label className="custom-control custom-checkbox">
                            <Link
                              to="#"
                              className="float-right small text-dark mt-1"
                              onClick={(e) => forgotPassword(history, "member")}
                            >
                              Forgot password?
                            </Link>
                            <Input
                              type="checkbox"
                              name="remember_me"
                              id="remember_me"
                              className="custom-control-input"
                              onChange={(e) => onChange(e)}
                              checked={remember_me}
                            />
                            <span className="custom-control-label text-dark">
                              Remember me
                            </span>
                          </Label>
                        </FormGroup>
                        <div className="form-footer mt-2">
                          <button className="btn btn-primary btn-block">
                            {" "}
                            SignIn{" "}
                          </button>
                        </div>
                      </CardBody>
                    </Form>
                  )}
                  {loginOrTwoFactor === "twofactor" && (
                    <Form onSubmit={(e) => onSubmitTwoFactor(e)}>
                      <CardHeader>
                        <h3 className="card-title">
                          Two factor authentication
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Alert />
                        <FormGroup>
                          <Label className="form-label text-dark">
                            One time password*
                          </Label>
                          <Input
                            type="otp"
                            placeholder="Enter OTP"
                            autoComplete="otp"
                            name="otp"
                            maxLength="50"
                            value={otp}
                            onChange={(e) => onChange(e)}
                            required
                            invalid={errorList.otp ? true : false}
                          />
                          <Errors current_key="otp" key="otp" />
                        </FormGroup>
                        <Row>
                          <Col xs="4">
                            <div className="form-footer mt-2">
                              <button className="btn btn-primary float-left">
                                {" "}
                                Verify{" "}
                              </button>
                            </div>
                          </Col>
                          {enableResendOTPOption && (
                            <Col xs="8">
                              {showResendButton === true ? (
                                <Button
                                  color="link"
                                  className="px-0 float-right"
                                  onClick={(e) => requestResendOTP()}
                                >
                                  Resend OTP
                                </Button>
                              ) : (
                                <span
                                  style={{ fontSize: "small" }}
                                  color="link"
                                  className="px-0 btn"
                                >
                                  {textToShowForResendTimer}
                                </span>
                              )}
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Form>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

MemberLogin.proTypes = {
  loadUser: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  forgotUsername: PropTypes.func.isRequired,
  registerRedirect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  otpSentForLogin: PropTypes.bool.isRequired,
  enableResendOTPOption: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.object.isRequired,
  loginMember: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  isAuthenticated: state.auth.isAuthenticated,
  authId: state.auth.authId,
  loading: state.auth.loading,
  otpSentForLogin: state.auth.otpSentForLogin,
  enableResendOTPOption: state.auth.enableResendOTPOption,
});

export default connect(mapStateToProps, {
  loginMember,
  loadUser,
  loadPage,
  loadAuthPage,
  forgotPassword,
  forgotUsername,
  registerRedirect,
  removeErrors,
})(MemberLogin);
